import React, { useState } from "react"
import { Link, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import ReactMarkdown from "react-markdown"
import Icon from "@mdi/react"
import { mdiClose } from "@mdi/js"

import SEO from "../components/seo"
import Layout from "../components/layout"
import Monetizer from "../components/monetizer"
import PageNav from "../components/page-nav"

import "../styles/blog-post.css"
import "../styles/image-modal.css"

const BlogPost = ({ data, pageContext }) => {
  const post = data.strapiBlogPost
  const blogSlug = pageContext.blogSlug
  const notAlphaNum = /\W/g
  const space = /\s/g
  const slugArray = data.allStrapiBlogPost.edges.map(({ node }) =>
    node.title.replace(space, "_").replace(notAlphaNum, "")
  )
  const currentIndex = slugArray.indexOf(blogSlug)
  const modifiedSlugArrayLength = slugArray.length - 1
  const ogImage = post.open_graph_image
  const isOgImage =
    ogImage !== null && ogImage !== "" && ogImage !== undefined
      ? ogImage.localFile.publicURL
      : false

  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [modalImage, setModalImage] = useState(null)

  const modalClick = target => {
    setModalImage(target)
    if (modalIsOpen === false) {
      setModalIsOpen(true)
      document.getElementById("image-modal").showModal()
    } else {
      document.getElementById("image-modal").close()
      setModalIsOpen(false)
    }
  }

  return (
    <Layout>
      <SEO
        title={post.title}
        description={post.excerpt}
        pageImage={isOgImage}
        pathname={blogSlug}
        article
      />
      <div className="blog-post-container">
        <Monetizer />
        <div className="blog-post-main">
          <div className="blog-post-header">
            <h1 className="blog-post-title">{post.title}</h1>
            <h4 className="blog-post-date">published on {post.publish_date}</h4>
          </div>
          <div className="blog-post-image">
            <GatsbyImage image={getImage(post.media?.localFile)} alt="" />
          </div>
          <ReactMarkdown
            className="blog-post-body"
            children={post.blog_body}
            components={{
              a: ({ node, ...props }) =>
                props.href.startsWith("/") ? (
                  <Link to={`${props.href}`} {...props} />
                ) : (
                  <a {...props} target="_blank" />
                ),
            }}
          />
          <ul className="media-scroller snaps-inline">
            {post.additional_images?.map((node, index) => {
              const sliderImage = node.album_image?.sliderImage
              const modalImage = node.album_image?.modalImage
              return (
                <li key={index} className="ms-element">
                  <GatsbyImage
                    className="blog-post-scroller-image"
                    image={getImage(sliderImage)}
                    alt=""
                    loading="lazy"
                    objectFit="contain"
                    onClick={() => modalClick(modalImage)}
                  />
                </li>
              )
            })}
          </ul>
          <PageNav
            slugArray={slugArray}
            currentIndex={currentIndex}
            modifiedSlugArrayLength={modifiedSlugArrayLength}
          />
        </div>
      </div>
      <dialog className="image-modal" id="image-modal">
        {modalIsOpen && (
          <div className="image-modal-wrapper">
            <div className="image-modal-close" onClick={() => modalClick(null)}>
              <Icon path={mdiClose} size={1} />
              <span>close</span>
            </div>
            <GatsbyImage
              image={getImage(modalImage)}
              alt=""
              objectFit="contain"
            />
          </div>
        )}
      </dialog>
    </Layout>
  )
}

export const query = graphql`
  query($blogTitle: String!) {
    strapiBlogPost(title: { eq: $blogTitle }) {
      title
      publish_date(formatString: "M-D-YYYY")
      blog_body
      excerpt
      open_graph_image {
        localFile {
          publicURL
        }
      }
      media {
        localFile {
          publicURL
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, quality: 100)
          }
        }
      }
      additional_images {
        album_image {
          sliderImage: localFile {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, height: 200, quality: 100)
            }
          }
          modalImage: localFile {
            childImageSharp {
              gatsbyImageData(quality: 100)
            }
          }
        }
      }
    }
    allStrapiBlogPost(sort: { fields: publish_date, order: DESC }) {
      edges {
        node {
          title
          publish_date
        }
      }
    }
  }
`
export default BlogPost
