import React from 'react'
import { Link } from 'gatsby'
import Icon from '@mdi/react'
import { mdiChevronLeft, mdiChevronRight } from  '@mdi/js'

import '../styles/page-nav.css'

const PageNav = (props) => {
  const {
    currentIndex,
    slugArray,
    modifiedSlugArrayLength
  } = props

  const prevPage = currentIndex - 1
  const nextPage = currentIndex + 1
  const notFirstEntry = currentIndex !== 0
  const notLastEntry = currentIndex !== modifiedSlugArrayLength

  return (
    <div className="page-nav-container">
      <Link 
        className={notFirstEntry ? `page-nav-button` : `page-nav-button-disabled`}
        to={notFirstEntry ? `/stories/${slugArray[prevPage]}` : undefined}
      >
        <Icon
          path={mdiChevronLeft}
          color={notFirstEntry ? `var(--text-med)` : `var(--text-xtra-dark)`}
          size={1}
        />
        Previous
      </Link>
      <Link 
        className="page-nav-home-link"
        to="/stories" 
      >
        Back to Stories
      </Link>
      <Link 
        className={notLastEntry ? `page-nav-button` : `page-nav-button-disabled`} 
        to={notLastEntry ? `/stories/${slugArray[nextPage]}` : undefined}
      >
        Next
        <Icon
          path={mdiChevronRight}
          color={notLastEntry ? `var(--text-med)` : `var(--text-xtra-dark)`}
          size={1}
        />
      </Link>
    </div>
  )
}

export default PageNav
